import {
    http
} from '@/http/axios.js'

export const API_GET_DICT_TYPE_LIST = '/system/dict/type/list';


// 字典详情
export function getDictListAPI(params) {
    return http({
        url: `/admin/dict/page`,
        method: 'get',
        params
    })
}


// 字典详情
export function getDictDetail(id) {
    return http({
        url: `/admin/dict/detail`,
        method: 'get',
        params: {id}
    })
}


export function editDict(data) {
    return http({
        url: `/admin/dict/edit`,
        method: 'POST',
        data
    })
}

export function addDict(data) {
    return http({
        url: `/admin/dict/add`,
        method: 'post',
        data
    })
}


export function getDictValues(dictCode) {
    return http({
        url: `/admin/dictEnum/list`,
        method: 'get',
        params: {dictCode}
    })
}

export function addDictValue(data) {
    return http({
        url: `/admin/dictEnum/add`,
        method: 'post',
        data
    })
}



export function editDictValue(data) {
    return http({
        url: `/admin/dictEnum/edit`,
        method: 'POST',
        data
    })
}



export function delDictValue(id) {
    return http({
        url: `/admin/dictEnum/delete`,
        method: 'POST',
        data: {id}
    })
}

export function getDictValue(id) {
    return http({
        url: `/admin/dictEnum/detail`,
        method: 'GET',
        params: {id}
    })
}